import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'

import * as s from '../pages/news.module.scss'
import Link from '../components/Common/Link'

const CdeFirstStepTowardConstructionDigitalization: React.FC = () => {
  return (
    <>
      <SEO
        title="Common Data Environment (CDE) - The First Step Toward Construction Digitalization"
        description="Learn how a Common Data Environment (CDE) streamlines construction project management. Discover the benefits of using SIGNAX DOCS for centralized document storage, real-time collaboration, and version control, ensuring improved efficiency, transparency, and compliance throughout the project lifecycle."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Common Data Environment (CDE) - The First Step Toward Construction
          Digitalization
        </Typography>
        <Typography variant="h2" color="blue">
          What is CDE?
        </Typography>
        <Typography variant="body1">
          A Common Data Environment (CDE) is a cloud-based system for legally
          compliant engineering document management in construction. It’s a
          centralized space where project information is stored, with
          customizable access controls, document versioning, file viewing, and
          markup tools to facilitate project approval processes and issue
          tracking.
        </Typography>
        <Typography variant="body1">
          A CDE benefits construction projects across all lifecycle stages—from
          design and construction through to operation—by organizing information
          exchange, enabling access to updated project data for all
          stakeholders, assigning tasks to team members and contractors, and
          monitoring project progress within a unified information platform.
        </Typography>
        <Typography variant="h2" color="blue">
          Analysis on CDE Solutions
        </Typography>
        <Typography variant="body1">
          Nikita Pospelov, Global Business Director at SIGNAX and experienced
          BIM manager, has recently contributed to the discourse on CDEs with an{' '}
          <Link
            href="https://www.linkedin.com/pulse/exploring-common-data-environments-place-landscape-nikita-pospelov-eo4lf/?trackingId=Dwwz8z8z7GnybI6rzvcr4g%3D%3D"
            rel="nofollow noreferrer"
            target="_blank"
          >
            insightful&nbsp;article
          </Link>{' '}
          analyzing the evolving landscape of CDE systems. Nikita highlights the
          ongoing trends in integrating CDEs with other platforms, particularly
          focusing on the shift towards hybrid systems that blend multiple
          functionalities. By examining how features like version control,
          real-time collaboration, and interoperability are implemented across
          various platforms, he underscores the need for adaptable solutions
          that can meet specific project requirements. Nikita's analysis serves
          as a valuable resource for understanding the versatility and strategic
          application of CDEs within the broader context of IT and construction
          technology.
        </Typography>
        <Typography variant="body1">
          For a deeper dive into the specific capabilities of leading CDE
          solutions and how they compare, please refer to this{' '}
          <Link
            href="https://docs.google.com/spreadsheets/d/1b2U_dh9hVu9oMgQ3kcO-A0njkIuKcxzouxX4IFfx2L0/edit?usp=sharing"
            rel="nofollow noreferrer"
            target="_blank"
          >
            CDE comparison spreadsheet.
          </Link>{' '}
        </Typography>
        <Typography variant="h2" color="blue">
          CDE Across Project Stages:
        </Typography>
        <List>
          <li>
            <b>Planning: </b> The client creates a centralized space to store
            all preliminary project documents. Necessary documents are signed
            and reviewed with contractors, master plans are developed and
            approved, and all participants gain access to required permits,
            technical specifications, and survey data.
          </li>
          <li>
            <b>Design: </b> CDE grants access to design documentation for all
            stakeholders, facilitating information preparation, review, and
            tender processes. Project revisions, comments, and approvals are
            managed here, enabling seamless transitions to construction.
          </li>
          <li>
            <b>Construction: </b> Provides complete access to approved project
            documentation, current updates from the construction site, task
            assignments, preparation and review of final documentation, and
            digital signature capabilities.
          </li>
          <li>
            <b>Operations: </b> The facility manager can access final
            documentation, technical data, specifications, and maintenance
            schedules. It allows for planning and coordinating renovation,
            modernization, and reconstruction phases.
          </li>
          <li>
            <b>Decommissioning: </b> CDE supports decommissioning planning,
            building dismantling, and waste disposal, as well as sale or
            repurposing options.
          </li>
        </List>
        <Typography variant="body1">
          The term CDE (Common Data Environment) was first recorded in the
          BS1192-2007 standard and has since become integral to managing data in
          Building Information Modeling (BIM) and Digital Twins in construction.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/cde-first-step-toward-construction-digitalization/image-1.jpg"
            alt="What is CDE?"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Benefits of CDE
        </Typography>
        <Typography variant="body1">
          A CDE enables more effective collaboration among all participants.
          Using SIGNAX DOCS, users gain access to essential project data through
          a dedicated hub on their preferred server. Each organization can
          control access to project licenses and data shared with other team
          members, ensuring secure and efficient collaboration.
        </Typography>
        <Typography variant="body1">
          CDE supports over 50 file formats, including CAD drawings and BIM
          models, making those an accessible tool for all participants, even
          those not directly involved in model development.
        </Typography>
        <Typography variant="body1">
          CDE ensures transparency in workflows, enhancing decision-making and
          information sharing across project teams. It optimizes document access
          and control, reduces errors, and accelerates their resolution.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/cde-first-step-toward-construction-digitalization/image-2.jpg"
            alt="Benefits of CDE"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          CDE - The Foundation for Construction Digitalization
        </Typography>
        <Typography variant="body1">
          CDE plays a critical role in the digitalization of construction,
          offering a centralized platform to manage and share information across
          a project’s lifecycle. It serves as the foundation for implementing
          digital methodologies like BIM and Digital Twins, supporting a variety
          of project needs:
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>
            <b>Centralized Database: </b> All project data, including drawings,
            models, specifications, and contracts, is stored in one place,
            making information easily accessible.
          </li>
          <li>
            <b>Collaborative Work and Coordination: </b> CDE enables seamless
            information exchange and action coordination between all project
            participants.
          </li>
          <li>
            <b>Version and Change Management: </b> CDE provides an organized
            approach to tracking revisions and managing model and document
            versions, minimizing risks of errors or discrepancies.
          </li>
          <li>
            <b>Process Automation: </b> CDE simplifies automated processes like
            deadline notifications, task management, and automated reporting.
          </li>
          <li>
            <b>Analytics and Reporting: </b> CDE offers tools for data analysis,
            report generation, and performance monitoring, supporting informed
            decision-making and process optimization.
          </li>
          <li>
            <b>Compliance with Standards and Regulations: </b> CDE supports
            adherence to standards like ISO 19650 for BIM information
            management.
          </li>
        </ol>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/cde-first-step-toward-construction-digitalization/image-3.jpg"
            alt="CDE - The Foundation for Construction Digitalization"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          SIGNAX DOCS: A CDE for Construction Project Management
        </Typography>
        <Typography variant="body1">
          SIGNAX DOCS is a digital platform for managing documents and project
          information in construction. It offers tools for storing, organizing,
          sharing, and managing project data throughout the project lifecycle.
          The platform ensures real-time collaboration, enabling teams to access
          the latest project information from any location on computers or
          mobile devices.
        </Typography>
        <Typography variant="body1">
          With version control and audit tracking, SIGNAX DOCS minimizes errors
          and maintains data integrity. Its seamless integration with other
          systems, including Microsoft Project and Office, enhances project
          efficiency and coordination.
        </Typography>

        <Typography variant="h3">Key Features of SIGNAX DOCS</Typography>
        <div className="flex flex-wrap" style={{ gap: '10px 24px' }}>
          <List>
            <li>Cloud version or On-premise deployment (client servers)</li>
            <li>Excellent mobile version</li>
            <li>2D/3D viewer</li>
            <li>File versions</li>
            <li>Reviews workflows</li>
            <li>Issues, comments, markups</li>
          </List>
          <List>
            <li>E-signature integration</li>
            <li>Issued for construction stamp</li>
            <li>QR-codes</li>
            <li>Annual licenses per user</li>
            <li>Enterprise version with unlimited number of users</li>
          </List>
        </div>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          Today, implementing a CDE is essential for efficient project
          management in the design and construction sectors. SIGNAX DOCS
          provides an integrated platform for data storage, management, and
          collaboration, enhancing project transparency, tracking, and
          compliance.
        </Typography>
        <Typography variant="body1">
          SIGNAX DOCS empowers teams to manage projects in a structured,
          data-driven, and transparent manner, improving construction quality,
          reducing costs, and ensuring timely project completion.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default CdeFirstStepTowardConstructionDigitalization
